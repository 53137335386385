import React from "react";
import parse from 'html-react-parser'
import styled from "styled-components"

const IntroTextInner = styled.div`
  max-width:640px;
  line-height:1.6rem;
  font-size:1.2rem;
  margin-bottom:3rem;

  p{
  margin-bottom:2rem;
  }
`;

export default function IntroText(props) {
  return (
    <IntroTextInner className="testingtesting123">
      {
        parse(props.content)
      }
    </IntroTextInner>
  )
}

