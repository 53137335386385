import React, { Component } from "react"
import styled from "styled-components"
import IntroText from "../components/introText"
import Header from "../components/header"
import locomotiveScroll from "locomotive-scroll"
import { HeaderFunc } from "../scrollFunctions/headerFunc"
import IconWrap from "../components/IconWrap"
import CurrentPage from "../components/CurrentPageIdentifier"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

const ContactInfoWrapper = styled.div`
  font-family: "Oswald";
  padding-top: 2rem;

  a {
    color: var(--text-color);
    text-decoration: none;
    transition: 0.5s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    pointer-events: all;
    font-size: clamp(1.2rem, 3vw, 5rem);
    width: 100%;
    display: block;

    &.email {
      margin-bottom: 4rem;
      text-transform: uppercase;
    }

    &:hover {
      opacity: 0.5;
    }
  }
`

export default class ContactPage extends Component {
  constructor() {
    super()
    this.state = { scroll: {} }
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const scroll = new locomotiveScroll({
      el: this.scrollRef.current,
      smooth: true,
      smoothMobile: true,
      smartphone: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      },
    })

    scroll.on("call", func => {
      if (func === "headerFunc") {
        HeaderFunc()
      }
    })

    this.setState({ scroll })
  }

  componentDidUpdate() {
    this.state.scroll.update()
    // this.state.scroll.update()
    // this.state.scroll.start()
  }

  componentWillUnmount() {}

  render() {
    const { data } = this.props
    // console.log(this.props);

    return (
      <div>
        <Helmet>
          <title>Contact | David Lindsay</title>
          <meta
            name="description"
            content="Get in touch with David Lindsay | Photographer | Tadcaster | Yorkshire"
          />
        </Helmet>
        <IconWrap />
        <CurrentPage />
        <div className="scroller" ref={this.scrollRef}>
          <Header
            className="header"
            state={this.state}
            headerText={
              this.props.data.page.title === "Home"
                ? "David Lindsay"
                : this.props.data.page.title
            }
          />
          {data.page.content ? (
            <IntroText content={data.page.content}></IntroText>
          ) : (
            ""
          )}
          <ContactInfoWrapper>
            <a href={"tel:" + data.page.acf_contact.phoneFormatted}>
              {data.page.acf_contact.phone}
            </a>
            <a className="email" href={"mailto:" + data.page.acf_contact.email}>
              {data.page.acf_contact.email}
            </a>
            <a
              href={data.page.acf_contact.instagram}
              rel="noreferrer"
              target="_blank"
            >
              INSTAGRAM
            </a>
            <a
              href={data.page.acf_contact.facebook}
              rel="noreferrer"
              target="_blank"
            >
              FACEBOOK
            </a>
            <a
              href={data.page.acf_contact.twitter}
              rel="noreferrer"
              target="_blank"
            >
              TWITTER
            </a>
            <a
              href={data.page.acf_contact.linkedin}
              rel="noreferrer"
              target="_blank"
            >
              LINKEDIN
            </a>
          </ContactInfoWrapper>
        </div>
      </div>
    )
  }
}

// export default function ContactPage({data}) {

//   return(

//     <div>

//       <IntroText content={data.page.content}></IntroText>

//       <ContactInfoWrapper>
//         <a href="tel:+447786160847">07786 160 847</a>
//         <a className="email" href="mailto:david@photosbydavid.co.uk">DAVID@PHOTOSBYDAVID.CO.UK</a>
//         <a href="http://instagram.com" rel="noreferrer" target="_blank">INSTAGRAM</a>
//         <a href="http://instagram.com" rel="noreferrer" target="_blank">FACEBOOK</a>
//         <a href="http://instagram.com" rel="noreferrer" target="_blank">TWITTER</a>
//         <a href="http://instagram.com" rel="noreferrer" target="_blank">LINKEDIN</a>
//       </ContactInfoWrapper>
//     </div>
//   )
// }

export const query = graphql`
  query ContactQuery {
    page: wpPage(id: { eq: "cG9zdDo3Nw==" }) {
      id
      title
      content
      acf_contact {
        email
        facebook
        instagram
        linkedin
        phone
        phoneFormatted
        twitter
      }
    }
  }
`
